import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { IlMedioevoComponent } from './pages/il-medioevo/il-medioevo.component';
import { ComeSiGiocaComponent } from './pages/m-game/come-si-gioca/come-si-gioca.component';
import { ElencoArgomentiComponent } from './pages/m-game/elenco-argomenti/elenco-argomenti.component';
import { LandingMTicketMShopComponent } from './pages/m-ticket-m-shop/landing-m-ticket-m-shop/landing-m-ticket-m-shop.component';
import { MShopElencoComponent } from './pages/m-ticket-m-shop/m-shop/m-shop-elenco/m-shop-elenco.component';
import { MShopSchedaComponent } from './pages/m-ticket-m-shop/m-shop/m-shop-scheda/m-shop-scheda.component';
import { MTicketComponent } from './pages/m-ticket-m-shop/m-ticket/m-ticket.component';
import { GalleriaFotoComponent } from './pages/multimedia/galleria-foto/galleria-foto.component';
import { GalleriaVideoComponent } from './pages/multimedia/galleria-video/galleria-video.component';
import { MuseoMultimedialeComponent } from './pages/museo-multimediale/museo-multimediale.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PaginaCortesiaComponent } from './pages/pagina-cortesia/pagina-cortesia.component';
import { PaginaGenericaComponent } from './pages/pagina-generica/pagina-generica.component';
import { ArgomentoSalaComponent } from './pages/sale/argomento-sala/argomento-sala.component';
import { DettaglioSalaComponent } from './pages/sale/dettaglio-sala/dettaglio-sala.component';
import { ElencoSaleComponent } from './pages/sale/elenco-sale/elenco-sale.component';
import { CappelloVoltiComponent } from './pages/volti-della-corsa/cappello-volti/cappello-volti.component';
import { DettaglioVoltiComponent } from './pages/volti-della-corsa/dettaglio-volti/dettaglio-volti.component';
import { VideogalleryArgomentoComponent } from './pages/sale/videogallery-argomento/videogallery-argomento.component';
import { VideogalleryVoltiComponent } from './pages/volti-della-corsa/videogallery-volti/videogallery-volti.component';

const routes: Routes = [
  { path: '', component: HomePageComponent },
  { path: ':lang/0/:sectionName', component: HomePageComponent },
  { path: ':lang/4/:sectionName', component: PaginaGenericaComponent }, // privacy
  { path: ':lang/5/:sectionName', component: PaginaGenericaComponent }, // credits
  { path: ':lang/7/:sectionName', component: GalleriaFotoComponent },
  { path: ':lang/8/:sectionName', component: GalleriaVideoComponent },
  { path: ':lang/10/:sectionName', component: ElencoSaleComponent },
  { path: ':lang/10/:sectionName/:idDettaglio/:titoloDettaglio', component: DettaglioSalaComponent },
  { path: ':lang/10/:sectionName/:idDettaglio/:titoloDettaglio/:idArgomento/:titoloArgomento', component: ArgomentoSalaComponent },
  { path: ':lang/10/:sectionName/:idDettaglio/:titoloDettaglio/:idArgomento/:titoloArgomento/videogallery', component: VideogalleryArgomentoComponent },
  { path: ':lang/15/:sectionName', component: IlMedioevoComponent },
  { path: ':lang/22/:sectionName', component: CappelloVoltiComponent },
  { path: ':lang/22/:sectionName/:idDettaglio/:titoloDettaglio', component: DettaglioVoltiComponent },
  { path: ':lang/22/:sectionName/:idDettaglio/:titoloDettaglio/videogallery', component: VideogalleryVoltiComponent },
  { path: ':lang/24/:sectionName', component: MShopElencoComponent },
  { path: ':lang/24/:sectionName/:idDettaglio/:titoloDettaglio', component: MShopSchedaComponent },
  { path: ':lang/25/:sectionName', component: MuseoMultimedialeComponent },
  { path: ':lang/26/:sectionName', component: ComeSiGiocaComponent },
  { path: ':lang/28/:sectionName', component: LandingMTicketMShopComponent },
  { path: ':lang/30/:sectionName', component: ElencoArgomentiComponent },
  { path: ':lang/32/:sectionName', component: PaginaGenericaComponent }, // il progretto
  { path: ':lang/33/:sectionName', component: MTicketComponent }, // mTicket
  { path: ':lang/39/:sectionName', component: PaginaGenericaComponent }, // orari di apertura
  { path: ':lang/40/:sectionName', component: PaginaGenericaComponent }, // tariffe
  { path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{
    scrollPositionRestoration: 'enabled',
    initialNavigation: 'enabled'
  })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
