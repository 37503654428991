<div class="single-portfolio-area pt-80 game" *ngIf="traduzioni">
  <div class="container">
    <div class="row pb-20" *ngIf="!mostraEsitoFinale; else esitoFinale">
      <div class="col-lg-12">
        <div class="single-portfolio-wrap">
          <div class="single-port-content">
            <div class="single-port-title">
              <h3>{{traduzioni.acquistaOraBiglietto}}</h3>
            </div>
          </div>
          <div class="portfolio-decription-wrap">
            <div class="row">
              <div class="col-12">
                <form>
                  <div class="row">
                    <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="myForm">
                      <label for="">{{traduzioni.quandoVuoiVenire}}?</label>
                      <input [class.is-invalid]="objInputData.invalid && objInputData.touched" type="date" [min]="today" class="form-control" formControlName="data" >
                      <small *ngIf="((objInputData.invalid && objInputData.touched) || objInputData.dirty) && objInputData.errors?.required">
                        {{traduzioni.campoObbligatorio}}
                      </small>
                      <small *ngIf="((objInputData.invalid && objInputData.touched) || objInputData.dirty) && objInputData.errors?.pattern">
                        {{traduzioni.valoreNonValido}}
                      </small>
                    </div>
                    <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="myForm">
                      <label for="">{{traduzioni.quantiSiete}}?</label>
                      <input [class.is-invalid]="objInputPersone.invalid && objInputPersone.touched" type="number" class="form-control" formControlName="persone" >
                      <small *ngIf="((objInputPersone.invalid && objInputPersone.touched) || objInputPersone.dirty) && objInputPersone.errors?.required">
                        {{traduzioni.campoObbligatorio}}
                      </small>
                      <small *ngIf="((objInputPersone.invalid && objInputPersone.touched) || objInputPersone.dirty) && objInputPersone.errors?.pattern">
                        {{traduzioni.valoreNonValido}}
                      </small>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-8" *ngIf="step == 1">
                <div class="single-shortcode-btn black-button medium-button mb-30">
                  <a class="btn-hover pointer" (click)="verificaDisponibilita()" [disabled]="loading" [innerHTML]="traduzioni.verificaDisponibilita" ></a>
                </div>
              </div>
              <div class="col-xl-8" *ngIf="step > 1">
                <div class="single-shortcode-btn black-button medium-button mb-30">
                  <a class="btn-hover pointer" (click)="modificaRicerca()" >{{traduzioni.modificaRicerca}}</a>
                </div>
              </div>
            </div>
            <div class="row divRisultati" *ngIf="step > 1">
              <div class="col-12">
                <div class="separatore pt-20 pb20"></div>
              </div>
              <div class="col-12" *ngIf="arrayOrari.length > 0; else noRisultati">
                <div class="row">
                  <div class="col-12 testoSelezionaOrario pb-30">
                    {{traduzioni.selezionareOrarioBiglietti}}
                  </div>
                  <div class="col-12">
                    <button *ngFor="let item of arrayOrari" class="btn btnOrario btn-success " [class.selected]="orarioSelezionato == item.id" type="button" (click)="selectOrario($event)" [value]="item.id"> {{item.denominazione}} </button>
                  </div>
                </div>
                <form>
                  <div class="row pt-40 form-group" [formGroup]="bigliettiForm">
                    <div class="col-12 pb-20 rigaTariffa" *ngFor="let item of arrayTariffe">
                      <div style="width: 100px">
                        <input type="number" class="form-control" [min]="0" [formControlName]="'tariffa_' + item.id" (change)="aggiornaDati()" >
                      </div>
                      <div class="testoTariffa">
                        <div class="denominazione" [innerHTML]="item.denominazione + ' | ' + item.prezzoTxt"></div>
                        <div class="descrizione" [innerHTML]="item.descrizione"></div>

                      </div>
                    </div>
                  </div>
                </form>
                <div class="row pt-20 pb-40 ">
                  <div class="col-12 totale">
                    {{traduzioni.importoTotale}}: <b>{{totale}} €</b>
                  </div>
                </div>
                <div class="row" *ngIf="orarioSelezionato == ''">
                  <div class="col-12 bigliettiMancanti">
                    {{traduzioni.attenzioneSelezionareOrario}}
                  </div>
                </div>
                <div class="row" *ngIf="bigliettiMancanti > 0">
                  <div class="col-12 bigliettiMancanti">
                    {{traduzioni.attenzioneSelezionareAncora}} <b>{{bigliettiMancanti}}</b> {{traduzioni.bigliettoiSu}} {{bigliettiRichiesti}} {{traduzioni.richiestoi}}
                  </div>
                </div>
                <div class="row pt-20" *ngIf="bigliettiMancanti < 0">
                  <div class="col-12 alertNumeroBiglietti">
                    {{traduzioni.erorreSelezionareMassimo}} <b>{{bigliettiRichiesti}}</b> {{traduzioni.bigliettoi}}
                  </div>
                </div>
                <div class="row" *ngIf="step == 2">
                  <div class="col-xl-8" *ngIf="(bigliettiMancanti == 0) && (orarioSelezionato != '')">
                    <div class="single-shortcode-btn black-button medium-button mb-30">
                      <a class="btn-hover pointer" (click)="controlloDati()" >{{traduzioni.prosegui}}</a>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="step > 2">
                  <div class="col-xl-8">
                    <div class="single-shortcode-btn black-button medium-button mb-30">
                      <a class="btn-hover pointer" (click)="tornaBiglietti()" >{{traduzioni.modificaSelezioneBiglietti}}</a>
                    </div>
                  </div>
                </div>

              </div>
              <ng-template #noRisultati>
                <div class="col-12">
                  {{traduzioni.nessunRisultato}}
                </div>
              </ng-template>



            </div>
          </div>

          <div class="row" *ngIf="step > 2">
            <div class="col-12">

              <form>
                <div class="row">
                  <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="anagraficaForm">
                    <label for="">{{traduzioni.cognome}}</label>
                    <input [class.is-invalid]="objInputCognome.invalid && objInputCognome.touched" type="text" class="form-control" formControlName="cognome" >
                    <small *ngIf="((objInputCognome.invalid && objInputCognome.touched) || objInputCognome.dirty) && objInputCognome.errors?.required">
                      {{traduzioni.campoObbligatorio}}
                    </small>
                    <small *ngIf="((objInputCognome.invalid && objInputCognome.touched) || objInputCognome.dirty) && objInputCognome.errors?.pattern">
                      {{traduzioni.valoreNonValido}}
                    </small>
                  </div>
                  <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="anagraficaForm">
                    <label for="">{{traduzioni.nome}}</label>
                    <input [class.is-invalid]="objInputNome.invalid && objInputNome.touched" type="text" class="form-control" formControlName="nome" >
                    <small *ngIf="((objInputNome.invalid && objInputNome.touched) || objInputNome.dirty) && objInputNome.errors?.required">
                      {{traduzioni.campoObbligatorio}}
                    </small>
                    <small *ngIf="((objInputNome.invalid && objInputNome.touched) || objInputNome.dirty) && objInputNome.errors?.pattern">
                      {{traduzioni.valoreNonValido}}
                    </small>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="anagraficaForm">
                    <label for="">EMAIL</label>
                    <input [class.is-invalid]="objInputEmail.invalid && objInputEmail.touched" type="email" class="form-control" formControlName="email" >
                    <small *ngIf="((objInputEmail.invalid && objInputEmail.touched) || objInputEmail.dirty) && objInputEmail.errors?.required">
                      {{traduzioni.campoObbligatorio}}
                    </small>
                    <small *ngIf="((objInputEmail.invalid && objInputEmail.touched) || objInputEmail.dirty) && objInputEmail.errors?.pattern">
                      {{traduzioni.valoreNonValido}}
                    </small>
                  </div>
                  <div class="form-group pb-20 col-12 col-sm-6" [formGroup]="anagraficaForm">
                    <label for="">{{traduzioni.telefono}}</label>
                    <input [class.is-invalid]="objInputTelefono.invalid && objInputTelefono.touched" type="text" class="form-control" formControlName="telefono" >
                    <small *ngIf="((objInputTelefono.invalid && objInputTelefono.touched) || objInputTelefono.dirty) && objInputTelefono.errors?.required">
                      {{traduzioni.campoObbligatorio}}
                    </small>
                    <small *ngIf="((objInputTelefono.invalid && objInputTelefono.touched) || objInputTelefono.dirty) && objInputTelefono.errors?.pattern">
                      {{traduzioni.valoreNonValido}}
                    </small>
                  </div>
                </div>
              </form>
            </div>
            <div class="col-xl-8" *ngIf="step == 3">
              <div class="single-shortcode-btn black-button medium-button mb-30">
                <a class="btn-hover pointer" (click)="vaiAlPagamento()" [disabled]="loading" >{{traduzioni.vaiPagamento}}</a>
              </div>
            </div>
            <div class="col-xl-8" *ngIf="step > 3">
              <div class="single-shortcode-btn black-button medium-button mb-30">
                <a class="btn-hover pointer" (click)="tornaAnagrafica()" >{{traduzioni.modificaDatiAnagrafica}}</a>
              </div>
            </div>


          </div>
          <div class="row" *ngIf="step > 3">
            <div class="col-md-9 col-sm-12 col-xs-12 m-t">
              <ngx-paypal [config]="payPalConfig"></ngx-paypal>
            </div>
          </div>

        </div>
      </div>
    </div>
    <ng-template #esitoFinale>
      <div class="row pb-20">
        <div class="col-lg-12">
          <div class="single-portfolio-wrap">
            <div class="single-port-content">
              <div class="single-port-title">
                <h3>{{traduzioni.acquistoCompletato}}!</h3>
              </div>
            </div>
            <div class="portfolio-decription-wrap">
              <div class="row">
                <div class="col-12">
                  {{traduzioni.testoFinaleAcquisto}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </ng-template>

  </div>
</div>
