import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { take, first } from 'rxjs/operators';
import { MainServiceService } from 'src/app/services/main-service.service';
import { MTicketService } from './m-ticket.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationsService } from 'src/app/services/notification.service';
import { IPayPalConfig, ICreateOrderRequest } from 'ngx-paypal';

@Component({
  selector: 'app-m-ticket',
  templateUrl: './m-ticket.component.html',
  styleUrls: ['./m-ticket.component.css']
})
export class MTicketComponent implements OnInit {
  arrayTariffe: any[];
  traduzioni: any;
  myForm: FormGroup;
  bigliettiForm: FormGroup;
  anagraficaForm: FormGroup;
  loading = false;
  arrayOrari: any[] = [];
  orarioSelezionato = "";
  today = "";
  bigliettiMancanti: number = 0;
  bigliettiRichiesti: number = 0;
  totale: string;
  datiBiglietti: any = null;
  datiAnagrafica: any;
  config: any;
  step: number = 1;
  mostraEsitoFinale: boolean = false;

  public payPalConfig?: IPayPalConfig;

  constructor(
    private service: MTicketService,
    private router: Router,
    private formBuilder: FormBuilder,
    private mainService: MainServiceService,
    private notificationsService: NotificationsService
  ) {

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    this.today = yyyy + '-' + mm + '-' + dd;

    this.myForm = this.formBuilder.group({
      data: ['', [Validators.required, Validators.pattern(this.mainService.datePattern)]],
      persone: ['', [Validators.required, Validators.pattern(this.mainService.numberPatter)]],
   });
}

  ngOnInit(): void {
    forkJoin([
      this.service.getConfig(),
      this.mainService.getTraductionsSubject().pipe(first(data => data != null))
    ])
    .subscribe(
      ([config, traduzioni]) => {
        this.config = config;
        this.traduzioni = traduzioni;
        this.mainService.showLoader(false);
      });
  }

  get objInputData() {
    return this.myForm.get('data');
  }

  get objInputPersone() {
    return this.myForm.get('persone');
  }

  get objInputCognome() {
    return this.anagraficaForm.get('cognome');
  }

  get objInputNome() {
    return this.anagraficaForm.get('nome');
  }

  get objInputTelefono() {
    return this.anagraficaForm.get('telefono');
  }

  get objInputEmail() {
    return this.anagraficaForm.get('email');
  }


  verificaDisponibilita() {
    if (this.loading) {
      return;
    }
    if (!this.myForm.valid) {
      this.mainService.validateAllFormFields(this.myForm);
      return false;
    }
    this.loading = true;

    this.service.verificaDisponibilita(this.myForm.value)
    .pipe(first())
    .subscribe(
      data => {
        this.step = 2;
        this.arrayOrari = data.orari;

        if (this.arrayOrari.length > 0) {
          this.arrayTariffe = data.tariffe;

          this.bigliettiForm = this.formBuilder.group({
            data: [this.myForm.controls['data'].value,],
            orario: ['', [Validators.required]]
         });

         for (let key in this.arrayTariffe) {
          this.bigliettiForm.addControl('tariffa_' + this.arrayTariffe[key].id, new FormControl('0', Validators.required));
          }

          this.bigliettiRichiesti = this.myForm.controls['persone'].value;

          this.aggiornaDati();
        }

        this.loading = false;
        this.objInputData.disable()
        this.objInputPersone.disable()
      },
      error => {
        this.notificationsService.show('error', '', error.error.message);
        this.loading = false;
      }
    );
  }


  modificaRicerca() {
    this.step = 1;
    this.objInputData.enable()
    this.objInputPersone.enable()
  }

  selectOrario(event) {
    this.orarioSelezionato = event.target.getAttribute("value");
    this.bigliettiForm.controls['orario'].setValue(this.orarioSelezionato);
  }

  aggiornaDati() {
    let totale = 0;
    this.bigliettiMancanti = this.myForm.controls['persone'].value;
    for (let key in this.arrayTariffe) {
      let id = this.arrayTariffe[key].id;

      let prezzo = this.arrayTariffe[key].prezzo;
      let quantita = this.bigliettiForm.controls['tariffa_' + id].value;

      if (!Number.isInteger(quantita) || (quantita < 0)) {
        quantita = 0;
        this.bigliettiForm.controls['tariffa_' + id].setValue(quantita);
      }

      this.bigliettiMancanti -= quantita;
      if (this.bigliettiMancanti < 0) {
        totale = 0;
      }
      else {
        totale += prezzo * quantita;
      }
    }

    this.totale = totale.toFixed(2).replace(".", ",");
  }


  controlloDati() {
    if (this.loading) {
      return;
    }

    if (!this.bigliettiForm.valid) {
      this.mainService.validateAllFormFields(this.bigliettiForm);
      return false;
    }


    this.datiBiglietti = this.bigliettiForm.value;

    for (let key in this.arrayTariffe) {
      let id = this.arrayTariffe[key].id;
      this.bigliettiForm.get('tariffa_' + id).disable();
      this.datiBiglietti['tariffa_' + id] = this.bigliettiForm.controls['tariffa_' + id].value;
    }

    console.log(this.datiBiglietti);

    this.step = 3;

    this.anagraficaForm = this.formBuilder.group({
      cognome: ['', [Validators.required]],
      nome: ['', [Validators.required]],
      telefono: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(this.mainService.emailPattern)]],
   });
  }


  tornaBiglietti() {
    this.datiBiglietti = null;
    this.step = 2;

    for (let key in this.arrayTariffe) {
      let id = this.arrayTariffe[key].id;
      this.bigliettiForm.get('tariffa_' + id).enable();
    }
  }


  vaiAlPagamento() {
    if (this.loading) {
      return;
    }

    if (!this.anagraficaForm.valid) {
      this.mainService.validateAllFormFields(this.anagraficaForm);
      return false;
    }

    this.objInputCognome.disable()
    this.objInputNome.disable()
    this.objInputTelefono.disable()
    this.objInputEmail.disable()

    this.datiAnagrafica = this.anagraficaForm.value;

    this.payPalConfig = null;
    this.initPaypalButton();

    this.step = 4;
  }



  tornaAnagrafica() {
    this.datiAnagrafica = null;

    this.objInputCognome.enable()
    this.objInputNome.enable()
    this.objInputTelefono.enable()
    this.objInputEmail.enable()

    this.step = 3;
  }

  private initPaypalButton(): void {
    var totale = 0;
    var items = new Array();

    for (let key in this.arrayTariffe) {
      let id = this.arrayTariffe[key].id;

      if (this.datiBiglietti['tariffa_' + id] && (this.datiBiglietti['tariffa_' + id] > 0)) {
        totale += this.arrayTariffe[key].prezzo * this.datiBiglietti['tariffa_' + id];

        items.push({
          name: this.arrayTariffe[key].denominazione,
          quantity: String(this.datiBiglietti['tariffa_' + id]),
          unit_amount: {
              currency_code: 'EUR',
              value: String(this.arrayTariffe[key].prezzo)
          }
        });
      }
    }

    this.payPalConfig = {
      currency: 'EUR',
      clientId: this.config.clientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
              currency_code: 'EUR',
              value: String(totale),
              breakdown: {
                  item_total: {
                      currency_code: 'EUR',
                      value: String(totale)
                  }
              }
          },
          items: items
        }]
      },
      advanced: {
        commit: 'true',
      },
      style: {
        label: 'pay',
        layout: 'vertical'
      },
      onApprove: (data, actions) => {
        actions.order.get().then(details => {
        });
      },
      onClientAuthorization: (data) => {
        const additionalInfo = {'totale' : totale, 'data' : this.datiBiglietti.data, 'orario': this.datiBiglietti.orario};
        this.finalizePayment(data, additionalInfo);

      },
      onCancel: (data, actions) => {
        this.mainService.showLoader(false);
      },
      onError: err => {
        this.mainService.showLoader(false);
      },
      onClick: (data, actions) => {
        if (data.fundingSource !== 'card') { // il pagamento con carta non avviene su finestra modale per cui non devo bloccare la finestra
//          this.mainService.showLoader(true);
        }
      },
    };
  }


  finalizePayment(data, paymentData) {
    this.mostraEsitoFinale = true;
    this.mainService.showLoader(false);
/*
    this.service.finalizePayment(data, paymentData)
    .pipe(first())
    .subscribe(
      data => {
        this.mainService.showLoader(false);
        this.notificationsService.show('success', 'Pagamento effettuato con successo', 'Riceverai al tuo indirizzo email il riassunto del tuo acquisto');
      },
      error => {
        this.mainService.showLoader(true);
        let errorText = error.error.message ? error.error.message : "Errore non gestito durante l'operazione.<br>Prima di riprovare verificare se l'addebito è andato a buon fine";
        this.notificationsService.show('error', '', errorText);
      }
    )
*/
  }
}
